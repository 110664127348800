/**************/
/* Title Page */
/**************/
.title-page {
  background: '#E8E9EA';
  /* width: 100%; */
  /* min-height: calc(100vh - 200px); */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.header-title {
  font-weight: 600;
  font-size: 24px;
}

.get-in-touch {
  border: 1px solid #40804E;
  padding: 8px;
}

.get-in-touch-a-tag {
  text-decoration: none;
}

.get-in-touch-text {
  color: #40804E;
  font-weight: 400;
  font-size: 18px;
}

.main-page {
  display: flex;
  padding: 100px;
  justify-content: space-between;
}

.title-box {
  /* max-width: 700px; */
  width: 50%;
}

.title-definition-div {
  display: flex;
  justify-content: space-between;
}

.title-definition-div-2 {
  display: flex;
}

.title-definition-div-3 {
  margin-bottom: 3px;
}

.green-underline-div {
  margin: 0px 10px;
}

.underline {
  background: rgba(64, 128, 78, 0.5);
  /* width: 100%; */
  height: 20px;
  margin-top: -2vh;
}

.title-text {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 700;
  font-size: calc(30px + 3vw);
  white-space: nowrap;
}

.title-text-italics {
  font-family: 'Old Standard TT';
  font-style: italic;
  font-weight: 700;
  font-size: calc(30px + 3vw);
  margin-left: 15px;
}

.title-subtext {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  font-size: calc(20px + 2vw);
}

.title-subtext-2 {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  font-size: calc(16px + 1.5vw);
  margin-top: 60px;
}

/* .title-image {
  height: 740px;
} */

.founder-image {
  width: 40%;
  height: auto;
}

/**********************/
/* Meet the Investors */
/**********************/
.meet-investors-div {
  background: #40804E;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meet-investors-title {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: -20px;
}

.meet-investors-text {
  font-weight: 600;
  font-size: 32px;
  color: white;
}

.margin-horizontal {
  margin: 0px 5px;
}

.underline-small {
  background: rgba(255, 255, 255, 0.5);
  /* width: 105%; */
  height: 15px;
  margin-top: -1.5vh;
  margin-left: -2.5%;
}

.image-text-div {
  display: flex;
  align-items: center;
  margin: 75px 0;
  margin: 7% 15%;
}

.image-eliot {
  width: 30vw;
  margin-right: 5vw;
}

.image-kristen {
  width: 30vw;
  margin-left: 5vw;
}

.name-text {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 500;
  font-size: calc(28px + 1vw); 
  margin-bottom: 3%;
  color: white;
}

.bio-text {
  font-weight: 300;
  font-size: 18px;
  color: #DFDFDF;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 100px solid #ffffff;
  margin-bottom: -100px;
  transform: rotate(180deg);
}

.triangle-up-2 {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 100px solid rgba(64, 128, 78, 0.5);
  margin-bottom: -50px;
  transform: rotate(180deg);
}


/*************/
/* Portfolio */
/*************/
.portfolio-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-title {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 100px;
  margin-bottom: -20px;
}

.portfolio-text {
  font-weight: 600;
  font-size: 32px;
  color: #40804E;
}

.portfolio-underline-small {
  background: rgba(64, 128, 78, 0.5);
  width: 105%;
  height: 15px;
  margin-top: -1.5vh;
  margin-left: -2.5%;
}

.logos {
  display: flex;
  margin: 5%;
}

.logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 30px;
}

.logo-image {
  height: 4vw;
  margin: 30px 0px;
}

.logo-image:hover {
  filter: drop-shadow(10px 10px 4px #c0c0c0);
}


/****************/
/* Half Circles */
/****************/
.half-circle-white {
  width: 8vw;
  height: 4vw; /* as the half of the width */
  margin: auto;
  background-color: #ffffff;
  border-bottom-left-radius: 1000px;  /* 100px of height + 10px of border */
  border-bottom-right-radius: 1000px; /* 100px of height + 10px of border */
  border-top: 0;
}

.half-circle-green {
  width: 8vw;
  height: 4vw; /* as the half of the width */
  margin: auto;
  background-color: #40804E;
  border-top-left-radius: 1000px;  /* 100px of height + 10px of border */
  border-top-right-radius: 1000px; /* 100px of height + 10px of border */
  border-bottom: 0;
}


/**************/
/* About Page */
/**************/
.about-page {
  /* width: 100%; */
  background: #40804E;
  padding: 0px 30px;
  width: unset;
}

.about-title {
  color: white;
  margin-top: 20px;
}

.about-div {
  display: flex;
  align-items: center;
}

.about-text-div {
  margin: 25px 40px 40px 0;
}

.about-text {
  margin: 20px 0px;
  color: white;
  font-weight: 300;
  font-size: 18px;
}


/**************/
/*** Footer ***/
/**************/
.footer-div {
  padding: 20px;
}

.footer {
  background: white;
  color: #40804E;
}

.footer-title {
  font-weight: 300;
  font-size: 20px;
}

.footer-text {
  font-weight: 200;
  font-size: 16px;
  margin-left: 15px;
}

.copyright-text {
  font-weight: 200;
  font-size: 16px;
  text-align: right;
  margin-top: 20px;
}

.twitter-logo {
  height: 20px;
  margin-left: 10px;
}


@media only screen and (max-width: 750px) {
  .main-page {
    flex-direction: column;
    align-items: center;
    padding: 30px
  }

  .title-box {
    width: 80%;
  }

  .title-subtext-2 {
    margin-top: 40px;
  }

  .founder-image {
    width: 80%;
    height: auto;
    margin-top: 50px;
  }

  .image-text-div {
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
  }

  .image-eliot {
    margin-top: 25px;
    margin-bottom: 15px;
    width: 50vw;
  }

  .image-kristen {
    margin-top: 25px;
    width: 50vw;
  }

  .logos {
    flex-direction: column;
    margin: 80px 0 50px 0;
  }

  .logo-image {
    height: 10vw;
  }
}